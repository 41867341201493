import dayjs from "dayjs";

export default {
  data() {
    return {
      rejectReason: ""
    };
  },
  methods: {
    async rejectRenewal(renewal) {
      this.$store.commit(
        "alertComponentValue",
        this.$store.getters.webRenewalRejectReasons[1]
      );
      const success = await new Promise(resolve => {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Reject Reason",
          components: [
            {
              name: "v-select",
              items: this._.values(this.$store.getters.webRenewalRejectReasons)
            }
          ],
          actions: [
            {
              text: "Submit",
              color: "primary",
              handler: async () => {
                this.rejectReason = this.$store.getters.alertComponentValue;
                this.$root.$emit("setLoading", true);
                await this.$api
                  .rejectTransaction(
                    {
                      transactionID: renewal.transactionID,
                      rejectReason: this.rejectReason
                    },
                    {
                      502: () => {
                        this.$root.$emit("setLoading", false);
                        this.$root.$emit(
                          "push-alert",
                          "The server is currently experiencing issues, please try again.",
                          {
                            color: "error"
                          }
                        );
                      }
                    }
                  )
                  .catch(() => {
                    this.$root.$emit("setLoading", false);
                    this.$store.dispatch("hideGlobalAlert");
                    resolve(false);
                  });
                this.$root.$emit("setLoading", false);
                this.$store.dispatch("hideGlobalAlert");
                resolve(true);
              }
            },
            {
              text: "Cancel",
              color: "error",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
                resolve(false);
              }
            }
          ]
        });
      });

      return success;
    },
    async printDecals(decals) {
      const failures = [];

      for (let i = 0; i < decals.length; i++) {
        const decal = decals[i];
        this.$store.commit("transObj", decal); // todo> refactor decal printing in hub app to use transObj instead of registrationObj
        this.setPrintVars(decal, decal);

        const itemsToPrint = [{ doc: "decal", copies: 1 }];
        if (this.countyConfig.printRegistrationLaserCopy) {
          itemsToPrint.push({ doc: "decal", copies: 1, index: 0 });
        }

        try {
          await this.$hubapp.print({
            itemsToPrint,
            transObj: decal,
            starTransaction: decal,
            registrationObj: this.$store.getters.registrationObj,
            countyConfig: this.$store.getters.countyConfig,
            test: false
          });
        } catch (error) {
          failures.push(decal);
        }
      }
    },
    canBeAccepted(renewal) {
      if (
        renewal.newPlate &&
        renewal.newPlate.class !== renewal.plateClassCode
      ) {
        return false;
      }

      const plateClass = this.$store.getters.plateClasses.find(plate => {
        return plate.plateClassCode === renewal.plateClassCode;
      });

      if (plateClass) {
        const currentPlateDate =
          plateClass.plateDates[plateClass.plateDates.length - 1];
        if (
          dayjs(renewal.registrationExpiresDate)
            .subtract(1, "year")
            .isBefore(currentPlateDate.availableDate)
        ) {
          return true;
        }
        return currentPlateDate.issueYear === renewal.issueYear.toString();
      }

      return !renewal.echeckpending;
    },
    acceptTooltipText(renewal) {
      if (!this.hubAppIsResponsive) {
        return "Hub App is Not Running";
      }

      if (
        renewal.newPlate &&
        renewal.newPlate.class !== renewal.plateClassCode
      ) {
        return `New Class ${renewal.newPlate.class} Required`;
      }

      if (renewal.echeckpending) return "E-Check waiting period active";

      const plateClass = this.$store.getters.plateClasses.find(plate => {
        return plate.plateClassCode === renewal.plateClassCode;
      });

      if (!plateClass) {
        return "";
      }

      const currentPlateDate =
        plateClass.plateDates[plateClass.plateDates.length - 1];

      if (
        dayjs(renewal.registrationExpiresDate)
          .subtract(1, "year")
          .isBefore(currentPlateDate.availableDate)
      ) {
        return "";
      }

      if (dayjs().isBefore(dayjs(currentPlateDate.availableDate))) {
        return "New Metal Unavailable";
      }

      if (currentPlateDate.issueYear !== renewal.issueYear.toString()) {
        return "New Metal Needed";
      }
      return "";
    }
  }
};
