<template>
  <div v-if="isInitFinished" v-cloak class="process26">
    <div class="detailsTable">
      <div :class="{ 'transaction-menu-container': menuIsShown }">
        <TransactionsMenu
          v-if="showLinks && transactionType !== 'Online Renewal'"
          @changeTransaction="initialize"
        ></TransactionsMenu>
      </div>
      <div :class="{ 'vehicle-detail-container': menuIsShown }">
        <div id="detailsRight">
          <h1>{{ transactionType }}</h1>
          <div>
            <div class="panel">
              <displayVehicleData
                :processObj="processObj"
                :isNewOwner="isNewOwner"
                :parentShowMoreVehicleInfo="showMoreVehicleInfo"
                @showMoreVehicleInfoChanged="showMoreVehicleInfo = $event"
                :readOnly="readOnly && canBeAccepted(renewal)"
                :isTitleIssuingTrans="isTitleIssuingTrans"
                :attributes="attributes"
                :validation="validation"
                @feeChange="handleFeeChange"
                :isFromBackButton="isFromBackButton"
              ></displayVehicleData>
            </div>
          </div>

          <div
            v-if="transactionType == 'Online Renewal'"
            class="floating-footer"
          >
            <div class="float-foot-item float-foot-left trans-actions">
              <v-btn color="primary" @click="() => cancel(true)">
                <v-icon color="white">arrow_back</v-icon>
                <span class="white-text">Details</span>
              </v-btn>
              <v-btn
                color="primary"
                @click="$router.push({ name: 'OnlineRenewals' })"
              >
                <v-icon color="white">arrow_back</v-icon>
                <span class="white-text">Renewals</span>
              </v-btn>
            </div>
            <template v-if="isProcessable">
              <div class="float-foot-item float-foot-right trans-actions">
                <v-tooltip
                  top
                  :disabled="acceptTooltipText(updatedRenewal) === ''"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-btn
                        class="accept"
                        :disabled="
                          !canBeAccepted(updatedRenewal) || !hubAppIsResponsive
                        "
                        @click="validateFields"
                        color="success"
                      >
                        <span class="white-text">Accept</span>
                      </v-btn>
                    </span>
                  </template>
                  {{ acceptTooltipText(updatedRenewal) }}
                </v-tooltip>
              </div>
              <div class="float-foot-item float-foot-right">
                <div>
                  <v-btn
                    class="reject"
                    @click="rejectRenewalFinish"
                    color="error"
                  >
                    <span class="white-text">Reject</span>
                  </v-btn>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="floating-footer">
            <div class="float-foot-item float-foot-left trans-actions">
              <div>
                <template
                  v-if="
                    $store.getters.resultsArray &&
                    $store.getters.resultsArray.length > 1 &&
                    ![
                      'Title And Registration',
                      'Title Only',
                      'EZ Tag'
                    ].includes(transactionType)
                  "
                >
                  <v-btn color="red" @click="$router.push({ name: 'Results' })">
                    <v-icon color="white">arrow_back</v-icon>
                    <span class="white-text">Results</span>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    color="red"
                    @click="() => cancel()"
                    v-if="
                      processObj.transaction.isDeficientFinish !== true &&
                      (![
                        'Title And Registration',
                        'Title Only',
                        'New Placard',
                        'EZ Tag',
                        'Temporary Tag Only',
                        'Registration Only'
                      ].includes(transactionType) ||
                        (transactionType === 'New Placard' &&
                          vin !== undefined))
                    "
                  >
                    <v-icon color="white">arrow_back</v-icon>
                    <span class="white-text">Details</span>
                  </v-btn>
                  <v-btn
                    color="red"
                    @click="$router.push({ name: 'EZTag' })"
                    v-else-if="transactionType == 'EZ Tag'"
                  >
                    <v-icon color="white">arrow_back</v-icon>
                    <span class="white-text">EZ Tag Results</span>
                  </v-btn>
                  <v-btn
                    color="red"
                    @click="$router.push({ name: 'DeficientTransactions' })"
                    v-else-if="processObj.transaction.isDeficientFinish"
                  >
                    <v-icon color="white">arrow_back</v-icon>
                    <span class="white-text">Deficients</span>
                  </v-btn>
                </template>
              </div>
            </div>
            <div
              id="rightActions"
              class="float-foot-item float-foot-right trans-actions"
            >
              <v-switch
                v-if="isTitleIssuingTrans"
                v-model="assignTitle"
                label="Assign Title Number"
              />
              <v-text-field
                v-if="showEzTagRejectInput"
                label="Reject Reason"
                solo
                :error="['', null, undefined].includes(ezTagRejectReason)"
                :error-messages="
                  ['', null, undefined].includes(ezTagRejectReason)
                    ? 'Required'
                    : ''
                "
                v-model="ezTagRejectReason"
              />
              <v-select
                v-if="!assignTitle && isTitleIssuingTrans"
                placeholder="Reject Reason"
                solo
                :error="incompleteRejectReason == null"
                :error-messages="
                  incompleteRejectReason == null ? 'Required' : ''
                "
                v-model="incompleteRejectReason"
                :items="rejectReasons"
              />
              <div>
                <v-btn
                  v-if="transactionType === 'EZ Tag'"
                  color="red"
                  @click="rejectEZTag"
                >
                  Reject
                </v-btn>
                <v-btn id="actionButton" @click="validateFields()">
                  <template v-if="transactionType == 'Restore Vehicle Record'"
                    >Rebuild</template
                  >
                  <template
                    v-else-if="
                      processObj.transaction.isDeficientFinish != undefined
                    "
                    >Complete Deficient</template
                  >
                  <template v-else-if="transactionType == 'EZ Tag'"
                    >Complete</template
                  >
                  <template v-else>Go To Checkout</template>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lock-indicator" v-if="assignedUser != null">
      <div>Assigned to {{ assignedUser.fullname }}</div>
      <v-icon> lock </v-icon>
    </div>
    <v-dialog
      v-if="transactionType == 'Title Destroy'"
      width="500"
      persistent
      :value="transactionType == 'Title Destroy'"
    >
      <v-card>
        <div class="delete-dialog">
          <h2>You have selected to destroy this title.</h2>
          <p>Please confirm your selection.</p>
          <hr />
          <p>
            VIN: <b>{{ processObj.vehicle.vin }}</b>
            <br />
            Title: <b>{{ processObj.title.titleNo }}</b>
            <br />
            Owner: <b>{{ processObj.owners[0].customerName }}</b>
            <br />
            <template
              v-if="
                processObj.owners[0].physicalAddress.streetNumber ==
                  undefined ||
                processObj.owners[0].physicalAddress.streetNumber == ''
              "
            >
              Address:
              <b>{{ processObj.owners[0].physicalAddress.address1 }}</b>
            </template>
            <template v-else>
              Address:
              <b
                >{{ processObj.owners[0].physicalAddress.streetNumber }}
                {{ processObj.owners[0].physicalAddress.address1 }}</b
              >
            </template>
            <br />
            City/State/Zip:
            <b
              >{{ processObj.owners[0].physicalAddress.city }}
              {{ processObj.owners[0].physicalAddress.state }}
              {{ processObj.owners[0].physicalAddress.postalCode }}</b
            >
          </p>

          <v-btn
            color="red"
            @click="
              $router.push({
                name: 'Details',
                params: {
                  vin: processObj.vehicle.vin,
                  make: processObj.vehicle.makeCode,
                  year: processObj.vehicle.modelYear
                }
              })
            "
            >Cancel</v-btn
          >
          <v-tooltip bottom nudge-bottom="20px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="green" @click="destroyTitle">
                Destroy Title
              </v-btn> </template
            ><span
              >Return mail on title mailed out to customer.</span
            ></v-tooltip
          >

          <v-tooltip bottom nudge-bottom="20px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="green" @click="deTitle"
                >De-Title</v-btn
              ></template
            ><span>Remove current title from vin.</span></v-tooltip
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="transactionType == 'Delete Placard'"
      width="500"
      persistent
      :value="transactionType == 'Delete Placard'"
    >
      <v-card>
        <div class="delete-dialog">
          <h2>You have selected to delete this placard.</h2>
          <p>Please confirm your selection.</p>
          <hr />
          <p>
            Placard No: <b>{{ processObj.placard.controlNo }}</b>
            <br />
            Owner: <b>{{ processObj.owners[0].customerName }}</b>
            <br />
            <template
              v-if="
                processObj.owners[0].physicalAddress.streetNumber ==
                  undefined ||
                processObj.owners[0].physicalAddress.streetNumber == ''
              "
            >
              Address:
              <b>{{ processObj.owners[0].physicalAddress.address1 }}</b>
            </template>
            <template v-else>
              Address:
              <b
                >{{ processObj.owners[0].physicalAddress.streetNumber }}
                {{ processObj.owners[0].physicalAddress.address1 }}</b
              >
            </template>
            <br />
            City/State/Zip:
            <b
              >{{ processObj.owners[0].physicalAddress.city }}
              {{ processObj.owners[0].physicalAddress.state }}
              {{ processObj.owners[0].physicalAddress.postalCode }}</b
            >
          </p>
          <v-text-field
            label="Comment"
            v-model="placardDeleteComment"
            :error="placardDeleteComment == ''"
            :error-messages="placardDeleteComment == '' ? 'Required' : ''"
          />

          <v-btn
            color="red"
            @click="
              $router.push({
                name: 'PlacardDetails',
                params: { placard: processObj.placard.controlNo }
              })
            "
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            :disabled="placardDeleteComment == ''"
            @click="deletePlacard"
            >Delete Placard</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showInsurancePrompt"
      width="500"
      persistent
      :value="showInsurancePrompt"
    >
      <v-card>
        <div class="delete-dialog">
          <h2>There is an EIVS stop on this vehicle.</h2>
          <p>Please enter insurance information to continue.</p>
          <hr />
          <p>
            <v-autocomplete
              label="NAIC / Company Name"
              :items="insuranceCompanyAutoComplete"
              v-model="processObj.vehicle.insurance.NAIC"
            />
            <v-text-field
              label="Policy Number"
              v-model="processObj.vehicle.insurance.PolicyNo"
            />
          </p>

          <v-btn color="red" @click="() => cancel()">Cancel</v-btn>
          <v-btn
            color="green"
            :disabled="
              [undefined, ''].includes(processObj.vehicle.insurance.NAIC) ||
              [undefined, ''].includes(processObj.vehicle.insurance.PolicyNo)
            "
            @click="showInsurancePrompt = false"
            >Continue</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newTitleDialog" persistent width="400px">
      <v-card>
        <v-card-title class="d-flex justify-center">Issue Title?</v-card-title>
        <v-card-text class="d-flex justify-center">
          Would you like to issue a new title?
        </v-card-text>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn color="success" @click="addNewTitle"> Yes </v-btn>
          <v-btn color="error" @click="removeNewTitle"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { invert } from "lodash";
import { states } from "@/assets/js/constants";
import { mapGetters } from "vuex";
import {
  title as defaultTitle,
  customer as defaultLessor,
  transactionRequest as defaultTransactionRequest
} from "@/assets/js/defaultTransactionRequestObjects/defaults";

// components
import displayVehicleData from "@/components/nonPageComponents/DisplayVehicleData";

// classes
import { TransactionRequest } from "@/classes/TransactionRequest";

// mixins
import renewal from "@/mixins/renewal.mixin.js";
import transaction from "@/mixins/transaction.mixin.js";
import fees from "@/mixins/fees.mixin";
import date from "@/mixins/date.mixin";
import transactionTypeSetup from "@/mixins/transactionTypeSetup.mixin.js";
import schemaValidation from "@/mixins/schemaValidation.mixin.js";
import searchFunctions from "@/mixins/searchFunctions.js";
const transactionTypes = invert(
  require("@/assets/jsonScaffolds/transactionTypes.json")
);

import { formatErrorResponse } from "@/assets/js/format.js";

export default {
  mixins: [
    renewal,
    transaction,
    fees,
    date,
    transactionTypeSetup,
    schemaValidation,
    searchFunctions
  ],
  components: {
    displayVehicleData
  },
  props: {
    placard: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    vin: String,
    make: String,
    year: [Number, String],
    transactionRequest: {
      type: Object,
      default: null
    },
    menuIsShown: {
      type: Boolean,
      default: true
    },
    renewal: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowProcess: {
      type: Boolean,
      default: true
    },
    isFromBackButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const processObj =
      this.transactionRequest ||
      JSON.parse(JSON.stringify(this.$store.getters.processObj));

    return {
      isInitFinished: false,
      showInsurancePrompt: false,
      rejectReasons: [],
      hasBackButtonLogicRun: false,
      incompleteRejectReason: null,
      placardDeleteComment: "",
      advancedSearch: true,
      assignTitle: true,
      isTitleIssuingTrans: false,
      isNewOwner: false,
      makesArrayDisplay: [],
      transObj: {},
      issueYearArray: "",
      ownerAddress: {},
      comment: "",
      comment2: "",
      links: {},
      showEzTagRejectInput: false,
      ezTagRejectReason: "",
      showModal: false,
      showLinks: false,
      today: new Date(),
      showReady: false,
      autoCompleteCustom: [],
      lienholdersData: {},
      timeoutObj: {},
      showOwner: false,
      showMailing: false,
      showTitleBrands: false,
      showMoreVehicleInfo: false,
      fairMarketValue: "0",
      showVin: false,
      licenseString: "",
      licenseScanType: "",
      timeoutHold: "",
      autoCompletePlate: [],
      checkPlateTimeoutHold: {},
      images: [],
      attributes: {
        classTaxPaid: { disabled: undefined },
        plateNo: { disabled: undefined },
        nameCode: { disabled: undefined },
        class: { disabled: undefined },
        issueYear: { disabled: undefined },
        plateExp: { disabled: undefined },
        plateCounty: { disabled: undefined },
        wheelTax: { disabled: undefined },
        conjunctionCode: { disabled: undefined },
        ownerName: { disabled: undefined },
        deafInd: { disabled: undefined }
      },
      selectedInsuranceIndex: "",
      selectedPolicyNum: "",
      states: states,
      processObj: processObj,
      oldVIN: this.vin,
      updatingFees: false, // indicates if fees are currently being updated (helpful for preventing submission during async update)
      hubAppIsResponsive: null,
      validation: {
        // this object provides a way for child components to indicate transaction validity to this component
        requiresSalesTaxPaidProof: false,
        salesTaxPaidProofProvided: false
      },
      newTitleDialog: false
    };
  },
  methods: {
    /**
     * Initialize transaction
     *
     * @param {Object} [processObj] - Specific tandr transaction request model (processObj) to use for this transaction. Defaults to the transactionRequest prop or $store.getters.processObj if not provided.
     */
    async initialize(processObj = undefined) {
      //code for back button fix. overwrites obj changes with the editObj that was saved on button click
      if (this.doBackButtonLogic) {
        this.processObj = this._.cloneDeep(this.$store.getters.editObj);

        this.$store.commit(
          "processObj",
          this._.cloneDeep(this.$store.getters.editObj)
        );
      } else {
        this.processObj = this._.cloneDeep(
          processObj ||
            this.transactionRequest ||
            this.$store.getters.processObj
        );
        this.$set(this.processObj, "tempComments", []);
      }

      //added so we can show this if its null
      if (
        this._.has(this.processObj, "vehicle.insurance") &&
        [undefined, null].includes(this.processObj.vehicle.insurance)
      ) {
        this.processObj.vehicle.insurance = { PolicyNo: "", NAIC: "" };
      }

      const reasons = await this.$api.incompleteRejectReason();
      if (typeof reasons == "object")
        this.rejectReasons = Object.values(reasons);

      //triggered if they refresh this page
      if (!this._.has(this.processObj, "vehicle")) {
        if (this.vin !== "" && this.vin !== undefined) {
          this.$router.push({
            name: "Details",
            params: {
              vin: this.vin,
              make: this.make,
              year: this.year
            }
          });
          return false;
        } else if (!this._.has(this.processObj, "placard")) {
          this.$router.push({ name: "Home" });
          return false;
        }
      }

      for (const k in this.makesArray) {
        if (typeof this.makesArray[k] !== "function") {
          this.makesArrayDisplay.push({ label: this.makesArray[k], code: k });
        }
      }

      //this is where the main setup logic is located
      this.transactionTypeSetup(); //this does all the switching for setup of transaction by type

      //for deficients we need to check here and undo any changes to the processObj
      if (
        this.processObj.transaction?.isDeficientFinish !== undefined ||
        this.doBackButtonLogic
      ) {
        this.processObj = this._.cloneDeep(this.$store.getters.processObj);
        if (this.doBackButtonLogic && this.doesVTRSRecordMatchTransaction)
          this.$store.commit(
            "processObj",
            this.reformatTandrObjectforTransactionScreen(
              this._.cloneDeep(this.VTRSVehicleRecord)
            )
          );
      }
      this.hasBackButtonLogicRun = true;

      //added so we can show this if its null
      if (
        this._.has(this.processObj, "vehicle.insurance") &&
        [undefined, null].includes(this.processObj.vehicle.insurance)
      ) {
        this.processObj.vehicle.insurance = { PolicyNo: "", NAIC: "" };
      }

      if (this._.has(this.processObj, "liens"))
        this.processObj.liens = this.sortLienholders(this.processObj.liens);

      // collect insurance information if a stop exists on the vehicle
      if (
        ["Registration Renewal", "Correction of Registration"].includes(
          this.transactionType
        ) &&
        this._.has(this, "processObj.vehicle.stops") &&
        Array.isArray(this.processObj.vehicle.stops) &&
        this.processObj.vehicle.stops.length > 0
      ) {
        for (let i = 0; i < this.processObj.vehicle.stops.length; i++) {
          if (
            ["18", "19"].includes(this.processObj.vehicle.stops[i].stopCode)
          ) {
            this.showInsurancePrompt = true;
            break;
          }
        }
      }
      if (!this._.has(this.processObj.owners[0].physicalAddress, "countyID")) {
        this.$set(
          this.processObj.owners[0].physicalAddress,
          "countyID",
          parseInt(this.$store.getters.countyId)
        );
      }
    },
    async rejectEZTag() {
      if (["", null, undefined].includes(this.ezTagRejectReason)) {
        this.showEzTagRejectInput = true;
        return;
      }
      this.$api.ezTagReject({
        transactionID: this.processObj.transactionID,
        rejectReason: this.ezTagRejectReason
      });
      this.$root.$emit("push-alert", "Ez Tag Was Rejected", {
        color: "success"
      });
      this.$router.push({ name: "EZTag" });
    },
    async deletePlacard() {
      this.$api.undoTransaction(
        this.processObj.transaction.transactionID,
        {
          transaction: {
            transactionTimestamp: this.getCurrentTimestamp(),
            countyID: parseInt(this.$store.getters.countyId),
            transactionID: this.processObj.transaction.transactionID,
            countyTransactionId: 0,
            invoiceNo: this.invoiceNo,
            transactionType: "CLZ",
            comments: [this.placardDeleteComment]
          }
        },
        true
      );
      this.$root.$emit("push-alert", "Placard was deleted", {
        color: "success"
      });
      this.$router.push({ name: "Home" });
    },
    sortLienholders: function (lienholders) {
      const sorted = lienholders.slice(0);
      sorted.sort(function (a, b) {
        const x = a.lienPrecedenceNo;
        const y = b.lienPrecedenceNo;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return sorted;
    },
    cancel(isFromORBack = false) {
      if (
        this._.has(this.processObj, "placard") &&
        (this.transactionType !== "New Placard" || this.vin === undefined)
      ) {
        this.$router.push({
          name: "PlacardDetails",
          params: {
            placard: this.placard
          }
        });
      } else {
        this.$router.push({
          name: "Details",
          params: {
            isFromORBack: isFromORBack,
            vin: this.vin,
            make: this.make,
            year: this.year
          }
        });
      }
    },
    /**
     * This function cleans the transaction object up and sends it to fees and verifies that it is correct before displaying fees page
     *
     */
    async continueToNextPage() {
      if (
        this.transactionType === "Manage Lienholders" &&
        this.countyConfig.promptForMLHTitleIssuance
      ) {
        this.$store.commit("printMLHTitle", undefined);
        const dischargedLiens = this.processObj.liens.filter(
          lien => lien.dischargeDate
        );
        // We *ONLY* discharged a lien
        if (
          this.processObj.liens.length ===
            this.VTRSVehicleRecord.liens?.length &&
          dischargedLiens.length > 0
        ) {
          this.newTitleDialog = true;
          // Wait for the clerk to make a selection
          await new Promise(resolve => (this.newTitlePromise = resolve));
        } else {
          // Add a lien in case they removed it previously and came back from checkout
          // to add one
          this.addNewTitle(false);
        }
      }
      let allTitleBrands;
      if (this._.has(this.processObj, "vehicle.allTitleBrands")) {
        allTitleBrands = this.processObj.vehicle.allTitleBrands; // preserve current title brands
        delete this.processObj.vehicle.allTitleBrands; // remove current title brands for api calls
      }

      this.$root.$emit("setLoading", true);

      if (!this.assignTitle) {
        this.processObj.transaction.statusCode = "2";
        const newComment = {
          comment: this.incompleteRejectReason,
          isGlobalComment: true
        };
        this.processObj.tempComments.push(newComment);
      } else {
        this.processObj.transaction.statusCode = "1";
      }

      if (this.processObj instanceof TransactionRequest)
        this.processObj = this.processObj.getReduced();

      const editObj = JSON.parse(JSON.stringify(this.processObj));
      if (
        this._.has(editObj, "vehicle.allTitleBrands") &&
        allTitleBrands != undefined
      )
        editObj.vehicle.allTitleBrands = allTitleBrands;
      this.$store.commit("editObj", editObj); //committing editobj here before clean up so we can show this page again if the user clicks back

      //does all the logic on dates and formating to send off for tandr
      try {
        this.processObj = await this.formatForTandR(
          JSON.parse(JSON.stringify(this.processObj))
        );
      } catch (error) {
        console.error(error);
        this.$root.$emit("setLoading", false);
        return;
      }

      if ("New Placard" === this.transactionType) {
        if (
          this._.has(this.processObj, "ownership") &&
          this.processObj.ownership.nameCode !== "0"
        ) {
          this.processObj.owners = [
            this.processObj.owners[this.placardOwnerIndex]
          ];
        }

        this.processObj = {
          placard: this.processObj.placard,
          transaction: this.processObj.transaction,
          owners: this.processObj.owners,
          starComments: this.processObj.starComments
        };

        this.$store.commit("titleData", undefined);
      }

      let transSendObj = this.processObj;

      const customErrors = {
        400: async error => {
          this.$root.$emit("setLoading", false);

          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: formatErrorResponse(error),
            icon: "error"
          });
        },
        403: async error => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: formatErrorResponse(error),
            icon: "error"
          });
        }
      };
      if (this.processObj.transaction.isDeficientFinish == undefined) {
        let doFeesCall = false;
        if (
          !["Restore Vehicle Record", "Online Renewal", "EZ Tag"].includes(
            this.transactionType
          )
        ) {
          doFeesCall = true;
        }

        if (doFeesCall) {
          try {
            this.$root.$emit("setLoading", true);
            /**
             * This is a temporary solution because we can't update the API's
             * easily. We will get rid of this monstrosity once the API
             * can get the fees right.
             */
            const feesRequestData = JSON.parse(JSON.stringify(this.processObj));
            //todo  remove this when we find the actual cause of the problem
            this.deleteBadDateToFixParsingProblem(feesRequestData);

            if (
              ["Re-Title Only", "Re-Title and Register"].includes(
                this.transactionType
              )
            ) {
              delete feesRequestData.title.salePrice;
              delete feesRequestData.title.vehicleCost;
            }

            if (this.transactionType === "Undercover Registration") {
              transSendObj = await this.$api.getUndercoverFees(
                feesRequestData,
                customErrors
              );
            } else {
              transSendObj = await this.$api.getTandRFees(
                feesRequestData,
                customErrors
              );
            }
            if (this._.has(transSendObj, "title")) {
              Object.assign(transSendObj, {
                title: Object.assign(transSendObj.title, {
                  salePrice: this.processObj.title.salePrice,
                  vehicleCost: this.processObj.title.vehicleCost
                })
              });
            }
            if (this._.has(transSendObj, "vehicle.titleBrands")) {
              for (
                let i = 0;
                i < transSendObj.vehicle.titleBrands.length;
                i++
              ) {
                if (
                  this._.has(transSendObj.vehicle.titleBrands[i], "createdDate")
                ) {
                  delete transSendObj.vehicle.titleBrands[i].createdDate;
                }
              }
            }
          } catch (error) {
            this.$root.$emit("setLoading", false);
            console.error(error);
            this.processObj = this._.cloneDeep(this.$store.getters.editObj);
            return false;
          }
        }

        if (this.transactionType !== "Online Renewal") {
          customErrors["422"] = async error => {
            this.$root.$emit("setLoading", false);
            this.handleDeficients(formatErrorResponse(error), transSendObj);
            throw error;
          };
        }
      }

      if (this._.has(transSendObj, "owners")) {
        // todo> this can be added to Owner.constructor if we got that route
        for (let i = 0; i < transSendObj.owners.length; i++) {
          if (this._.has(transSendObj.owners[i], "mailingAddress.countyID")) {
            delete transSendObj.owners[i].mailingAddress.countyID;
          }
        }
      }
      let transactionResponse;
      try {
        transactionResponse = await this.createTransaction(
          transSendObj,
          customErrors
        );

        /** Put the new title number from /tandr in the transObj.
         * This is how title numbers are assigned.
         */
        if (
          transactionResponse != undefined &&
          transactionResponse.titleNo != undefined &&
          transSendObj.title != undefined
        ) {
          transSendObj.title.titleNo = transactionResponse.titleNo;
        }
      } catch (error) {
        this.processObj = this._.cloneDeep(this.$store.getters.editObj);

        console.error(error);
        return false;
      } finally {
        this.$root.$emit("setLoading", false);
      }

      if (this.transactionType == "Restore Vehicle Record") {
        this.$store.commit("processObj", this.processObj);
        this.$router.push({
          name: "Details",
          params: {
            vin: this.processObj.vehicle.vin,
            make: this.processObj.vehicle.makeCode,
            year: this.processObj.vehicle.modelYear
          }
        });
      } else if (this.transactionType === "Online Renewal") {
        this.setWheelTaxDecalNumbers(transSendObj);

        const transactionRequest = this._.cloneDeep(transSendObj);
        transactionRequest.transaction.isVerify = false;
        const transactionResponse = await this.createTransaction(
          transactionRequest
        );

        this.$store.commit("transObj", transSendObj);
        if (this.processObj.vehicle !== undefined)
          this.processObj.vehicle.allTitleBrands = allTitleBrands; // restore title brands before committing
        this.$store.commit("processObj", this.processObj);
        this.transObj = transSendObj; // setting transObj since printTransaction > setPrintVars expects it
        const itemsToPrint = this.getItemsToPrint(
          transactionRequest,
          "Online Renewal",
          transactionResponse
        );
        this.printTransaction(
          itemsToPrint,
          transactionRequest,
          transactionResponse
        );
        this.$router.push({ name: "OnlineRenewals" });
      } else if (this.transactionType === "EZ Tag") {
        this.$store.commit("transObj", transSendObj);
        this.transObj = transSendObj; // setting transObj since printTransaction > setPrintVars expects it
        this.printTransaction(
          this.getItemsToPrint(transSendObj, this.transactionType),
          transSendObj
        );
        this.$router.push({ name: "EZTag" });
      } else if (this.processObj.transaction.isDeficientFinish != undefined) {
        const transactionRequest = this._.cloneDeep(transSendObj);
        const itemsToPrint = this.getItemsToPrint(
          transactionRequest,
          this.transactionType,
          transactionResponse
        );
        this.$root.$emit("setLoading", false);
        this.$store.commit("transObj", transSendObj);
        this.$router.push({
          name: "Documentation",
          params: {
            transactionRequest: transactionRequest,
            transactionResponse: transactionResponse,
            itemsToPrint
          }
        });
      } else {
        this.$store.commit("transObj", transSendObj);
        this.$root.$emit("setLoading", false);
        this.$router.push({ name: "CheckOut" });
      }
    },
    async handleDeficients(error, transSendObj) {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Error!",
        description: error,
        icon: "error",
        actions: [
          {
            text: "Fix issue",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              this.processObj = JSON.parse(
                JSON.stringify(this.$store.getters.editObj)
              );
            },
            color: "primary"
          },
          {
            text: "Continue as Deficient",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              transSendObj.transaction.statusCode = "D";
              this.$store.commit("transObj", transSendObj);
              this.$router.push({ name: "CheckOut" });
            },
            color: "#6E7D88"
          }
        ]
      });
    },
    isEqual: function (a, b) {
      const aProps = Object.getOwnPropertyNames(a);
      const bProps = Object.getOwnPropertyNames(b);
      if (aProps.length != bProps.length) {
        return false;
      }
      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    },
    print: function () {
      window.print();
    },
    autoCompleteAdd(current) {
      const temp = [];
      if (current == "" || current == undefined || current.indexOf(" ") == -1) {
        if (this.autoCompleteArr.toString() !== this.autoCompleteCustom) {
          this.autoCompleteCustom = JSON.parse(
            JSON.stringify(this.autoCompleteArr)
          );
        }
      } else {
        if (current.endsWith(" ")) {
          this.autoCompleteCustom = JSON.parse(
            JSON.stringify(this.autoCompleteArr)
          );
          for (let i = 0; i < this.autoCompleteCustom.length; i++) {
            temp.push(current + this.autoCompleteCustom[i]);
          }
          this.autoCompleteCustom = temp;
        }
      }
    },
    transferWipe() {
      Object.assign(this.processObj, {
        title: defaultTitle(),
        registration: Object.assign(this.processObj.registration, {
          currentMake: this.processObj.vehicle.makeCode,
          currentYear: this.processObj.vehicle.modelYear
        }),
        transaction: Object.assign(this.processObj.transaction, {
          comments: []
        }),
        lessor: defaultLessor(),
        liens: []
      });
    },
    newOwnerWipe() {
      const processObj = defaultTransactionRequest();
      processObj.registration.registrationExpiresDate = undefined;
      processObj.registration.newPlate = {};

      processObj.owners[0].physicalAddress.state =
        this.$store.getters.configState;
      processObj.owners[0].state = this.$store.getters.configState;
      processObj.owners[1] = {};
      processObj.owners[1].individualCustomer = {};
      for (let i = 0; i < processObj.owners.length; i++) {
        processObj.owners[i].customerID = null;
        processObj.owners[i].physicalAddressID = null;
      }

      processObj.liens = [];

      processObj.vehicle = this.processObj.vehicle;
      processObj.title.formerTitleState = this.$store.getters.configState;
      processObj.title.formerTitleNo = this.processObj.title.titleNo;
      processObj.title.formerIssueDate = this.processObj.title.titleIssueDate;

      this.processObj = processObj;
    },
    async validateFields() {
      //checking validations and if there are any then scroll to them
      if (
        document.querySelector(".error--text input") != undefined &&
        this.transactionType != "Restore Vehicle Record"
      ) {
        this.showMoreVehicleInfo = true;
        this.$nextTick(() => {
          this.$vuetify.goTo(
            document.querySelector(".error--text").parentElement
          );
          document.querySelector(".error--text input").focus();
        });
        return;
      }

      // registration validation
      if (this.processObj.registration) {
        if (
          this.validation.requiresSalesTaxPaidProof &&
          !this.validation.salesTaxPaidProofProvided
        ) {
          const hasProof = await new Promise(res => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Highway use tax required",
              description:
                "Customer must show proof that highway use tax has been paid. Do you have proof?",
              icon: "warning",
              actions: [
                {
                  text: "Yes",
                  handler: () => {
                    this.$store.dispatch("hideGlobalAlert");
                    res(true);
                  },
                  color: "primary"
                },
                {
                  text: "No",
                  handler: () => {
                    this.$store.dispatch("hideGlobalAlert");
                    res(false);
                  },
                  color: "#E0E0E0"
                }
              ]
            });
          });
          if (!hasProof) {
            return;
          }
        }

        if (this.isPermanentPlate(this.processObj.registration)) {
          this.processObj.registration.registrationExpiresDate =
            this.endOfTime.format();
        }
      }

      if (
        this.$store.getters.MVConfigurations.PromptNoLiens.val && // the no-lien prompt is enabled // we are assigning a title (former title number is defined and title number isn't)
        this._.has(this, "processObj.title.formerTitleNo") &&
        this.processObj.title.formerTitleNo != "" &&
        (this.processObj.title.titleNo == undefined ||
          this.processObj.title.titleNo == "") &&
        this.processObj.liens.length === 0 // no liens exist for this transaction
      ) {
        const hasNoLiens = await new Promise(res => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "This vehicle has no liens!",
            description: "Are these changes correct?",
            icon: "warning",
            actions: [
              {
                text: "Yes",
                handler: () => {
                  this.$store.dispatch("hideGlobalAlert");
                  res(true);
                },
                color: "primary"
              },
              {
                text: "No",
                handler: () => {
                  this.$store.dispatch("hideGlobalAlert");
                  res(false);
                },
                color: "#E0E0E0"
              }
            ]
          });
        });
        if (!hasNoLiens) {
          return;
        }
      }
      if (this._.has(this.processObj, "vehicle")) {
        var allTitleBrands = this.processObj.vehicle.allTitleBrands; // preserve current title brands before it is deleted in continueToNextPage (todo> remove when TransactionRequest is in full use)
      }
      if (
        this.transactionType === "Correction of Title" &&
        this.oldVIN !== this.$store.getters.processObj.vehicle.vin
      ) {
        this.processObj.vehicle.newVIN =
          this.$store.getters.processObj.vehicle.vin;
        this.processObj.vehicle.vin = this.oldVIN;
      }
      this.continueToNextPage();
      if (this._.has(this.processObj, "vehicle"))
        this.processObj.vehicle.allTitleBrands = allTitleBrands; // restore current title brands
      this.$store.commit("processObj", this.processObj);
    },
    deTitle() {
      this.processObj.title.statusID = 5;
      this.processObj.title.deTitle = true;
      this.destroyTitle();
    },
    async destroyTitle() {
      this.$root.$emit("setLoading", true);

      this.$api
        .vinSearch(this.processObj.vehicle.vin, true)
        .then(vehicle => {
          const documentToPrint = { doc: "201", copies: 1 };
          this.transObj = JSON.parse(vehicle[0].transactionJSON);
          this.$store.commit("transObj", this.transObj);
          this.printTransaction([documentToPrint], this.transObj, {
            authNo: vehicle[0].authNo
          });
        })
        .catch(e => {
          console.error(e);
          this.$root.$emit("push-alert", "There was an issue printing.", {
            color: "error"
          });
        });

      this.processObj.transaction.statusCode = "1";
      this.processObj.transaction.countyID = parseInt(
        this.$store.getters.countyId
      );
      if (this.processObj.lessor != undefined) delete this.processObj.lessor;
      if (this.processObj.liens != undefined) delete this.processObj.liens;
      if (this.processObj.registration != undefined)
        delete this.processObj.registration;
      if (this.processObj.vehicle.insurance != undefined)
        delete this.processObj.vehicle.insurance;

      //this block fixes the owners in the obj based on what namecode was decided, sets business / individual / more than one name
      this.nameCodeToOwnerData(
        this.processObj.owners,
        this.processObj.ownership
      );

      //this block reformats the owners changing id's to ints where it needs and deleting addresses if they are not needed.
      for (let i = 0; i < this.processObj.owners.length; i++) {
        if (this.processObj.owners[i].customerID == null) {
          delete this.processObj.owners[i].customerID;
        }
        if (this.processObj.owners[i].physicalAddressID == null) {
          delete this.processObj.owners[i].physicalAddressID;
        }
        if (
          this.processObj.owners[i].physicalAddress != undefined &&
          this.processObj.owners[i].physicalAddress.countyID != undefined
        ) {
          this.processObj.owners[i].physicalAddress.countyID = parseInt(
            this.processObj.owners[i].physicalAddress.countyID
          );
        }
        if (this._.has(this.processObj.owners[i], "mailingAddress")) {
          delete this.processObj.owners[i].mailingAddress;
        }
      }

      //this removes owners,lessors, and liens if they are null or empty

      for (let i = 0; i < this.processObj.owners.length; i++) {
        if (this.processObj.owners[i] == null) {
          this.processObj.owners.splice(i, 1);
        }
      }

      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      delete this.processObj.title.titleNo;
      this.processObj.transaction.transactionTimestamp =
        this.getCurrentTimestamp();

      this.processObj.transaction.invoiceNo = this.invoiceNo;

      this.processObj.transaction.isVerify = false;

      try {
        this.$api.transaction("/tandr/transaction", this.processObj);
      } catch (error) {
        this.$root.$emit("setLoading", false);
        this.$router.push({
          name: "Details",
          params: {
            vin: this.processObj.vehicle.vin,
            make: this.processObj.vehicle.makeCode,
            year: this.processObj.vehicle.modelYear
          }
        });
      }

      try {
        const sendData = {
          vin: this.processObj.vehicle.vin,
          page: 0
        };
        const resultsArrayTemp = await this.$api.inquirySearch(sendData);
        this.$store.commit("resultsArray", resultsArrayTemp);
        if (resultsArrayTemp === null) {
          //no results coming from general inquiry.
          this.$root.$emit("setLoading", false);
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: "There is a problem with the server.",
            icon: "error"
          });
          this.$router.push({ name: "Search" });
          return false;
        } else {
          this.$root.$emit("setLoading", false);
          this.$router.push({ name: "Results" });
        }
      } catch (error) {
        this.$router.push({ name: "Home" });
      } finally {
        this.$root.$emit("setLoading", false);
      }
    },
    transactionTypeSetup() {
      //list all slides
      this.links = [];
      if (this._.has(this.processObj, "vehicle")) {
        this.links.push({
          name: "Back To Details",
          action: "link",
          data: {
            name: "Details",
            params: {
              vin: this.processObj.vehicle.vin,
              make: this.processObj.vehicle.makeCode,
              year: this.processObj.vehicle.modelYear
            }
          }
        });
      }

      if (!this._.has(this.processObj, "transaction"))
        this.$set(this.processObj, "transaction", {});

      this.links = this.links.concat([
        { name: "Title", action: "jump", data: "vinJump" },
        { name: "Vehicle", action: "jump", data: "vehicleJump" },
        { name: "Plate", action: "jump", data: "plateJump" },
        { name: "Owners", action: "jump", data: "ownerJump" },
        { name: "Registrant/Lessee", action: "jump", data: "registrantJump" },
        { name: "Lienholders", action: "jump", data: "lienholderJump" },
        { name: "Comments", action: "jump", data: "comments" }
      ]);

      // set attribute defaults
      Object.keys(this.attributes).forEach(element => {
        this.attributes[element].disabled = false;
      });

      this.isTitleIssuingTrans = false;

      const titleObj = {};
      for (const key in this.processObj.title) {
        titleObj[key] = this.processObj.title[key];
      }
      this.$store.commit("titleData", titleObj);

      //remove the ones that will be expanded
      //moved these to transactionTypeSetup.mixin to unclutter this section
      const transactionTypeFunctionsMap = {
        "Registration Renewal": this.setupRenewal,
        "Online Renewal":
          this.processObj.placard !== undefined
            ? this.setupPlacardRenewal
            : this.setupRenewal,
        "Correction of Title": this.setupCorrectionOfTitle,
        "Title Destroy": this.setupTitleDestroy,
        "Re-Title Only": this.setupReTitleOnly,
        "Re-Title and Register": this.setupReTitleAndRegistration,
        "Duplicate Title": this.setupDuplicateTitle,
        "New Owner": this.setupNewOwner,
        "New Owner Title Only": this.setupNewOwnerTitleOnly,
        "TDR Sticker": this.setupTDRSticker,
        "Farm Permit": this.setupFarmPermit,
        "Transfer Registration to New": this.setupTransferRegistrationToNew,
        "Transfer Registration from Existing":
          this.setupTransferRegistrationFrom,
        "Replace Plate": this.setupReplacePlate,
        "EZ Tag": this.setupEZTag,
        "Correction of Registration": this.setupCorrectionOfRegistration,
        "Lost/Stolen Plate or Decal": this.setupLostStolen,
        "Charge Wheel Tax": this.setupChargeWheelTax,
        "Reprint Decal": this.setupReprintDecal,
        "Change Plate Class": this.setupChangePlateClass,
        "Manage Lienholders": this.setupManageLienholders,
        "Temporary Operators Permit": this.setupTOP,
        "Title And Registration": this.setupTAndR,
        "Undercover Registration": this.setupUnderCover,
        "Title Only": this.setupTitleOnly,
        "Registration Only": this.setupRegistrationOnly,
        "Title With Temporary Tag": this.setupTitleWithTOP,
        "Temporary Tag Only": this.setupTOPOnly,
        "Restore Vehicle Record": this.setupRestoreVehicle,
        "Reassign Registration": this.setupReassignRegistration,
        "New Placard": this.setupNewPlacard,
        "Placard Renewal": this.setupPlacardRenewal,
        "Replace Placard": this.setupPlacardReplace,
        "Delete Placard": this.setupDeletePlacard
      };

      if (this._.has(transactionTypeFunctionsMap, this.transactionType))
        transactionTypeFunctionsMap[this.transactionType]();

      //use deficient as is

      //this must be here so the api doesn't trigger an edit transaction
      delete this.processObj.transactionTypeID;
      [
        "transactionID",
        "vehicleID",
        "customerID",
        "issueYearID",
        "statusDate",
        "recordedDate",
        "countyTransactionID",
        "lastUpdateDate",
        "lastUpdateUserID"
      ].forEach(prop => {
        delete this.processObj.transaction[prop];
      });

      if (this._.has(this.processObj, "registration")) {
        this._.merge(this.processObj.registration, {
          countyID: this.countyId,
          cityWheelTaxInd: this.countyConfig.hasWheelTaxCities
        });
      }

      if (this._.has(this.processObj, "undercoverRegistration"))
        this.processObj.undercoverRegistration.countyID =
          this.$store.getters.countyId;

      //add this so links has a results when needed
      if (this.processObj.transaction.isDeficientFinish != undefined) {
        for (let i = 0; i < this.links.length; i++) {
          if (this.links[i].name == "Back To Details") {
            this.links.splice(i, 1);
          }
        }
        this.links.push({
          name: "Back to Results",
          action: "link",
          data: { name: "DeficientTransactions" }
        });
        this.isTitleIssuingTrans = false;
      } else {
        if (
          this.transactionType !== "Online Renewal" &&
          this.$store.getters.resultsArray != undefined &&
          this.$store.getters.resultsArray.length > 1
        ) {
          if (this.transactionType == "Restore Vehicle Record") {
            //this is because general results return in a different format
            this.links.push({
              name: "Back to Results",
              action: "link",
              data: { name: "Results" }
            });
          } else {
            this.links.push({
              name: "Back to Results",
              action: "link",
              data: { name: "Results" }
            });
          }
        }
      }
      //moved the functionality here to the continue funcion as per 30222

      this.$store.commit("sideLinks", this.links);
      //scroll screen to top
      this.showLinks = true;

      //defaulting the plate county if we need to
      if (
        this.processObj.registration != undefined &&
        (this.processObj.registration.countyID == "" ||
          this.processObj.registration.countyID == undefined)
      ) {
        this.processObj.registration.countyID = this.$store.getters.countyId;
      }

      //add instantPrintInd if office is configured to use it for that transaction type
      if (
        this._.includes(
          this.$store.getters.officeConfig.autoTitlePrintTransactionCodes,
          transactionTypes[this.transactionType]
        )
      ) {
        this.processObj.transaction.instantPrintInd = true;
      }
    },
    async rejectRenewalFinish() {
      if (await this.rejectRenewal(this.renewal)) {
        this.$router.push({ name: "OnlineRenewals" });
      }
    },
    async handleFeeChange() {
      this.updatingFees = true;
      await this.updatePayment();
      this.updatingFees = false;
    },
    async updatePayment() {
      let feeTotal = 0;
      const fees = this.processObj.transaction.fees;
      const payments = this.processObj.payments;

      if (!Array.isArray(payments) || payments.length === 0) {
        console.error("Attempted to update payment, but no payment exists.");
        return;
      }

      if (payments.length > 1) {
        console.error("Unexpected instance of multiple payments.");
        return;
      }

      if (payments[0].tenderType === "CreditCard") {
        // remove old credit card fees
        for (let i = 0; i < fees.length; i++) {
          if (fees[i].isCreditFee) fees.splice(i, 1);
        }

        // add updated credit card fees
        fees.concat(await this.getCreditCardFees());
      }

      for (let i = 0; i < fees.length; i++) {
        feeTotal += fees[i].feeAmount;
      }

      payments[0].amount = feeTotal;
    },
    addNewTitle(resolvePromise = true) {
      this.processObj.title = JSON.parse(
        JSON.stringify(this.VTRSVehicleRecord.title)
      );
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      delete this.processObj.title.titleNo;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      delete this.processObj.title.titleIssueDate;
      this.processObj.title.formerTNTitleID = this.processObj.title.titleID;
      delete this.processObj.title.titleID;
      this.newTitleDialog = false;
      if (resolvePromise) {
        this.$store.commit("printMLHTitle", true);
        this.newTitlePromise();
      }
    },
    removeNewTitle() {
      delete this.processObj.title;
      this.newTitleDialog = false;
      this.$store.commit("printMLHTitle", false);
      this.newTitlePromise();
    }
  },
  destroyed() {
    if (this.SSE !== null) this.SSE.close();
    clearTimeout(this.SSETimeout);
    if (this.isUnfinishedDraft && this.isFeatureEnabled("drafts")) {
      const unfinishedDrafts = this.$store.getters.unfinishedDrafts;
      if (unfinishedDrafts.length > 0) {
        for (let i = 0; i < unfinishedDrafts.length; i++) {
          if (unfinishedDrafts[i].vin == this.processObj.vehicle.vin) {
            this.$store.commit("removeUnfinishedDraft", i);
          }
        }
      }

      this.$store.commit("addUnfinishedDraft", {
        vin: this.processObj.vehicle.vin,
        transactionType: this.transactionType,
        time: Date.now(),
        obj: this.processObj
      });
      /** Don't show the message when switching transaction types or on checkout.
       *  We still want to save these as drafts though, just silently.
       */
      if (
        !["DirectTransactionChange", "CheckOut"].includes(
          this.$router.currentRoute.name
        )
      ) {
        this.$root.$emit("push-alert", "Vehicle Information Saved as Draft", {
          color: "info"
        });
      }
    }
    this.buttonBarContent = []; //killing footer when we dont need it
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: "isFeatureEnabled",
      transactionType: "transactionType",
      insuranceCompanies: "insuranceCompanies",
      autoCompleteArr: "autoCompleteArr",
      makesArray: "makes",
      vehicleTypesArray: "vehicleTypes",
      colorsArray: "vehicleColors",
      bodyTypesArray: "bodyTypes",
      countiesArray: "counties",
      fuelArray: "fuelTypes",
      titleCodes: "titleCodes",
      vehicleUse: "vehicleUse",
      odometerBrands: "odometerBrands",
      plateClasses: "plateClasses",
      inventoryPlateClasses: "inventoryPlateClasses",
      invoiceNo: "invoiceNo",
      pendingTransactionRequest: "pendingTransactionRequest",
      pendingTransaction: "pendingTransaction",
      countyConfig: "countyConfig",
      countyId: "countyId",
      VTRSVehicleRecord: "VTRSVehicleRecord",
      userObject: "userObject",
      placardOwnerIndex: "placardOwnerIndex"
    }),
    doBackButtonLogic() {
      return this.isFromBackButton && !this.hasBackButtonLogicRun;
    },
    insuranceCompanyAutoComplete() {
      const temp = [];
      for (let i = 0; i < this.insuranceCompanies.length; i++) {
        temp.push({
          disabled: false,
          value: this.insuranceCompanies[i].NAICID,
          text:
            this.insuranceCompanies[i].NAICID +
            " / " +
            this.insuranceCompanies[i].InsCoName
        });
      }
      return temp;
    },
    doesVTRSRecordMatchTransaction() {
      if (this.VTRSVehicleRecord === undefined) {
        return false;
      }

      if (
        [
          "Title Only",
          "Registration Only",
          "Temporary Tag Only",
          "Title And Registration"
        ].includes(this.transactionType)
      ) {
        return false;
      }

      if (
        this._.has(this.VTRSVehicleRecord, "vehicle") &&
        this.vin !== this.VTRSVehicleRecord.vehicle.vin
      ) {
        return false;
      }

      if (
        this._.has(this.VTRSVehicleRecord, "placard") &&
        this.placard !== this.VTRSVehicleRecord.placard.placardNo
      ) {
        return false;
      }

      return true;
    },
    assignedUser() {
      if (this.pendingTransaction?.assignedTo == undefined) return null;
      return (
        this.$store.getters.users.find(
          user => this.pendingTransaction.assignedTo.id === user.id
        ) || null
      );
    },
    isProcessable() {
      return (
        this.allowProcess &&
        this.renewal !== null &&
        this.renewal.status !== "REJECTED" &&
        !this.updatingFees
      );
    },
    updatedRenewal() {
      return {
        plateClassCode:
          this.processObj.registration?.currentPlate?.class ||
          this.processObj.registration?.newPlate?.class,
        issueYear:
          this.processObj.registration?.currentPlate?.issueYear ||
          this.processObj.registration?.newPlate?.issueYear,
        echeckpending: this.renewal.echeckpending,
        newPlate: this.renewal.newPlate,
        registrationExpiresDate:
          this.processObj.registration.registrationExpiresDate
      };
    },
    isUnfinishedDraft() {
      return (
        ![
          "New Placard",
          "Placard Renewal",
          "Replace Placard",
          "Online Renewal",
          undefined,
          null,
          ""
        ].includes(this.transactionType) &&
        ![undefined, null, ""].includes(
          this._.get(this.processObj, "vehicle.vin")
        ) &&
        this.processObj.vehicle != undefined &&
        !this._.has(this.processObj.transaction, "isDeficientFinish")
      );
    }
  },
  async created() {
    if (this.pendingTransactionRequest && !this.pendingTransaction.assignedTo) {
      try {
        await this.$api.queueAssign(
          this.pendingTransactionRequest.transactionID
        );
        this.pendingTransactionRequest.assignedTo = this.userObject.id;
      } catch (error) {
        const { vehicle } = this.pendingTransactionRequest;
        if (this.VTRSVehicleRecord) {
          this.$router.push({
            name: "Details",
            params: {
              vin: vehicle.vin,
              make: vehicle.makeCode,
              year: vehicle.modelYear
            }
          });
        } else {
          this.$router.push({ name: "Home" });
        }
        return;
      }
    }
    this.hubAppIsResponsiveCheck();
    await this.initialize(this.pendingTransactionRequest);

    if (
      this.processObj.courtesyRenewalsCount < 1 &&
      this.processObj.hasIncomplete
    ) {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Pending Incomplete",
        description:
          "There is a pending incomplete transaction at the state. Would you like to continue?",
        actions: [
          {
            text: "Yes",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              this.$router.push({ name: "Details " });
            },
            color: "primary"
          },
          {
            text: "No",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
            },
            color: "#E0E0E0"
          }
        ]
      });
    }

    this.isInitFinished = true;
  },
  watch: {
    assignTitle() {
      this.incompleteRejectReason = null;
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.pendingTransactionRequest &&
      this.pendingTransaction.assignedTo.id === this.userObject.id
    ) {
      if (to.name !== "Checkout") {
        this.$api.queueUnAssign(this.pendingTransactionRequest.transactionID);
      }
    }
    next();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

$menu-width: 225px;

.delete-dialog {
  padding: 20px;
  text-align: center;
}

#detailsRight {
  margin-left: 35px;
  margin-right: 35px;
}

#rightActions {
  text-align: right;
}

.trans-actions {
  padding: 0px 20px;
}

.transaction-menu-container {
  width: $menu-width;
}

.vehicle-detail-container {
  margin-top: $panel-margin;
  margin-left: $menu-width;
}

#businessName {
  width: 100%;
}
.ownerLabel {
  white-space: nowrap;
  margin-top: 25px;
  margin-right: 10px;
}
.ownersBlock input {
  margin-right: 5px;
}
.flex {
  display: flex;
  flex-direction: row;
}
.flexRight {
  width: 100%;
  justify-content: right;
}
.center {
  margin-left: -20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.titleSection {
  width: 20%;
}
.titleSection h3 {
  font-size: 20pt;
}
.inputSection {
  display: flex;
  flex-direction: row;
  width: 80%;
}
.leftInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.twoColumn .leftInputs {
  margin-right: 10%;
}
.twoColumn .rightInputs {
  margin-right: 5%;
}
.threeColumn {
  display: flex;
  margin-right: 5%;
}
.threeColumn .leftInputs {
  margin-right: 25px;
}
.middleInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.threeColumn .middleInputs {
  margin-right: 25px;
}
.rightInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fullInputWidth {
  margin-left: 19%;
  width: 80%;
}
.fullInputWidth > .rightInputs {
  padding-right: 5%;
}
.panel .selectInput {
  margin-top: -4px; //fixing this to match md input
  padding-top: 1px;
  padding-bottom: 4px;
}
.panel .selectInput select {
  width: 100%;
}

.panel hr {
  margin: 40px 0px 40px 0px;
}

.preloader-scan {
  position: relative;
  top: 80px;
  left: 0;
  right: 0;
  max-width: 200px;
  display: table;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}

.preloader-scan ul {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  list-style-type: none;
  text-align: center;
}

.preloader-scan li {
  display: inline-block;
  width: 2px;
  height: 50px;
  background-color: #444;
}

.modal {
  display: block;
  background-color: white;
  z-index: 9998;
  width: 50%;
  height: 500px;
  /*min-height: 530px;*/
  position: sticky;
  top: 15%;
  left: 25%;
  bottom: 4%;
  border-radius: 10px;
  border: 1px gray solid;
}

.modalClose {
  float: right;
  margin-right: 2%;
}

.modalBody {
  text-align: center;
  padding: 3%;
}

.modalButtons button {
  width: 200px;
}

#actionButton {
  background-color: green;
  color: white;
}

input[type="text"] {
  text-transform: uppercase;
}

#modalClose {
  font-size: 30px;
  float: right;
  margin: 15px;
}

@media print {
  .modal {
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
  }

  #modalLoad {
    height: 100%;
    overflow-y: visible;
  }
}

.input-barcode {
  position: absolute;
  z-index: 3;
  right: 24px;
  top: 32px;
}

.preloader-scan {
  top: 65px;
  position: relative;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}
.v-dialog button {
  margin: 0 4px;
}

.lock-indicator {
  display: flex;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 10px;
  background-color: darkred;
  color: white;
  border-radius: 4px;
  padding: 6px;
}

.lock-indicator div {
  margin-right: 10px;
}

h4 {
  margin: 10px 0;
}
</style>
