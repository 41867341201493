import dayjs from "dayjs";
import {
  registration,
  vehicle as defaultVehicle,
  transaction as defaultTransaction
} from "@/assets/js/defaultTransactionRequestObjects/defaults";
import undercoverDefault from "../assets/jsonScaffolds/undercover.default";

import newPlacardScaffold from "@/assets/jsonScaffolds/newPlacardScaffold.js";

export default {
  data() {
    return {
      transactionTypes: this._.invert(
        require("../assets/jsonScaffolds/transactionTypes.json")
      )
    };
  },
  methods: {
    setupRenewal() {
      const inventoryPlateClass = this.inventoryPlateClasses.find(
        plateClass => {
          return (
            plateClass.plateClassCode ===
            this.processObj.registration.currentPlate.class
          );
        }
      );

      if (
        inventoryPlateClass != undefined &&
        inventoryPlateClass.expirationCode === "P"
      )
        this.processObj.registration.registrationExpiresDate =
          this.$store.getters.endOfTime.format();
      // no expiration if plate is permanent
      else if (this.transactionType !== "Online Renewal") {
        // todo> temporarily disable for just Online Renewal (current plan is to have front end always handle expiration date)
        const expirationDate = dayjs(
          this.processObj.registration.registrationExpiresDate
        )
          .hour(0)
          .minute(0)
          .second(0);
        if (
          inventoryPlateClass != undefined &&
          inventoryPlateClass.expirationCode === "S" &&
          this.isFromBackButton === false
        ) {
          this.processObj.registration.registrationExpiresDate = expirationDate
            .add(1, "year")
            .endOf("month")
            .format();
        } else if (
          inventoryPlateClass != undefined &&
          Number.isInteger(inventoryPlateClass.expirationCode)
        ) {
          // if expirationCode represents the month index (1 = Jan, ..., 12 = Dec)
          const expDate = expirationDate
            .set("month", parseInt(inventoryPlateClass.expirationCode) - 1)
            .endOf("month");
          if (expDate.isBefore(dayjs())) {
            // if the expiration date is in the past, go to the next year for the next occurance of the expirationCode month
            expDate.add(1, "year");
          }

          this.processObj.registration.registrationExpiresDate =
            expDate.format();
        }
      }

      this.processObj.registration.isRenewal = true;
      if (this.transactionType !== "Online Renewal") {
        this.processObj.transaction.transactionType =
          this.transactionTypes["Registration Renewal"];
      } else {
        this.processObj.transaction.transactionType =
          this.transactionTypes["Online Renewal"];
      }

      delete this.processObj.registration.registrationBeginDate;
      if (this._.has(this.processObj, "title")) {
        delete this.processObj.title;
      }

      const disabledFields = [
        "nameCode",
        "ownerName",
        "conjunctionCode",
        "plateCounty",
        "deafInd"
      ];

      if (!this.canBeAccepted(this.processObj.vehicle)) {
        disabledFields.concat(["class", "issueYear", "plateExp", "plateNo"]);
      }

      // disable fields
      disabledFields.forEach(element => {
        this.attributes[element].disabled = true;
      });
      this.attributes.plateExp.style = "";
      delete this.processObj.placard;
    },
    setupCorrectionOfTitle() {
      this.isTitleIssuingTrans = true;
      this.processObj.transaction.isCorrective = true;
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      this.processObj.title.titleNo = "";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Correction of Title"];
      delete this.processObj.title.salePrice;
      delete this.processObj.title.vehicleCost;
      delete this.processObj.placard;
    },
    setupTitleDestroy() {
      this.processObj.transaction.transactionType =
        this.transactionTypes["Title Destroy"];
      this.processObj.title.statusID = 10;
    },
    setupReTitleAndRegistration() {
      this.isTitleIssuingTrans = true;
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      this.processObj.title.titleNo = "";
      this.processObj.transaction.isNewOwner = false;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Re-Title and Register"];
      this.processObj.registration.registrationTypeCode = "R";
      delete this.processObj.placard;
    },
    setupReTitleOnly() {
      this.isTitleIssuingTrans = true;
      this.isNewOwner = false;
      this.processObj.ownership.isNewOwner = false;
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      this.processObj.title.titleNo = "";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Re-Title Only"];
      this.processObj.owners[0].physicalAddress.countyID = parseInt(
        this.$store.getters.countyId
      );
      if (this._.has(this.processObj, "registration"))
        delete this.processObj.registration;

      delete this.processObj.placard;
    },
    setupDuplicateTitle() {
      this.isTitleIssuingTrans = true;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Duplicate Title"];
      this.processObj.title.titleReIssueReason = "D"; // this was speced as being just "D" (bug 31112)
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      delete this.processObj.title.salePrice;
      delete this.processObj.title.vehicleCost;

      this.processObj.title.titleNo = "";
      this.showVin = true;
      delete this.processObj.placard;
    },
    setupNewOwner() {
      this.isTitleIssuingTrans = true;
      this.isNewOwner = true;
      // Prevents transaction info from being wiped on deficient transactions
      if (!this.processObj.transaction.isDeficientFinish) this.newOwnerWipe();
      this.processObj.ownership.isNewOwner = true;
      this.processObj.transaction.transactionType =
        this.transactionTypes["New Owner"];
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      this.processObj.owners[0].physicalAddress.countyID = parseInt(
        this.$store.getters.countyId
      );
      delete this.processObj.registration.operatingZone;
      delete this.processObj.placard;
    },
    setupNewOwnerTitleOnly() {
      this.isTitleIssuingTrans = true;
      this.isNewOwner = true;
      // Prevents transaction info from being wiped on deficient transactions
      if (!this.processObj.transaction.isDeficientFinish) this.newOwnerWipe();
      this.processObj.ownership.isNewOwner = true;
      this.processObj.transaction.transactionType =
        this.transactionTypes["New Owner Title Only"];
      this.processObj.owners[0].physicalAddress.countyID = parseInt(
        this.$store.getters.countyId
      );
      if (this._.has(this.processObj, "registration"))
        delete this.processObj.registration;
      delete this.processObj.placard;
    },
    setupTDRSticker() {
      delete this.processObj.title.formerTitleNo;
      delete this.processObj.title.formerTitleState;
      this.processObj.revenueSticker = { revenueStickerNum: "" };
      this.processObj.transaction.transactionType =
        this.transactionTypes["TDR Sticker"];
      delete this.processObj.placard;
    },
    setupFarmPermit() {
      this.processObj.ownership.purchaseDate = new Date();
      this.processObj.transaction.transactionType =
        this.transactionTypes["Farm Permit"];
      this.processObj.registration.registrationTypeCode = "F";
      delete this.processObj.title;
      this.processObj.registration.newPlate.plateNo = "";
      this.processObj.registration.newPlate.class = "";
      this.processObj.registration.newPlate.issueYear = "";
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      delete this.processObj.placard;
    },
    setupTransferRegistrationToNew() {
      this.processObj.transaction.transactionType =
        this.transactionTypes["Transfer Registration"];
      this.transferWipe();
      this.processObj.registration.plateTransfer = true;
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.ownership.isNewOwner = true;
      this.isTitleIssuingTrans = true;
      this.isNewOwner = true;
      ["class", "issueYear", "plateExp", "plateNo"].forEach(element => {
        this.attributes[element].disabled = true;
      });
      this.attributes.plateExp.style = "";
      this.processObj.vehicle = defaultVehicle();
      delete this.processObj.registration.operatingZone;
      this.processObj = this.setupTitleBrands(this.processObj);
      delete this.processObj.placard;
    },
    setupTransferRegistrationFrom() {
      this.processObj.transaction.transactionType =
        this.transactionTypes["Transfer Registration"];
      this.processObj.registration.plateTransfer = true;
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.registration.newPlate = {
        plateNo: "",
        class: "",
        issueYear: ""
      };
      delete this.processObj.title.salePrice;
      delete this.processObj.placard;
    },
    setupReplacePlate() {
      delete this.processObj.title;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Replace Plate"];
      this.processObj.registration.registrationTypeCode = "R";
      delete this.processObj.placard;
    },
    setupCorrectionOfRegistration() {
      delete this.processObj.title;
      this.processObj.transaction.isCorrective = true;
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Correction of Registration"];
      this.attributes["nameCode"].disabled = true;
      delete this.processObj.placard;
    },
    setupEZTag() {
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      this.links = [];
      this.processObj.transaction.transactionType =
        this.transactionTypes["Title And Registration"];
      delete this.processObj.placard;
    },
    setupLostStolen() {
      delete this.processObj.title;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Lost/Stolen Plate or Decal"];
      this.processObj.transaction.isLostStolen = true;
      this.processObj.transaction.isDecalReplacement = true;
      delete this.processObj.placard;
    },
    setupChargeWheelTax() {
      this.processObj.transaction.transactionType =
        this.transactionTypes["Wheel Tax"];
      delete this.processObj.title.salePrice;
      delete this.processObj.placard;
    },
    setupReprintDecal() {
      this.processObj.transaction.isDecalReplacement = true;

      delete this.processObj.title;

      this.processObj.registration.registrationTypeCode = "R";

      this.processObj.transaction.transactionType =
        this.transactionTypes["Reprint Decal"];
      ["class", "issueYear", "plateExp", "plateNo", "plateCounty"].forEach(
        element => {
          this.attributes[element].disabled = true;
        }
      );
      this.attributes.plateExp.style = true;
      delete this.processObj.placard;
    },
    setupChangePlateClass() {
      delete this.processObj.title;
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Change Plate Class"];
      delete this.processObj.placard;
    },
    setupManageLienholders() {
      this.isTitleIssuingTrans = true;
      delete this.processObj.title.salePrice;
      this.processObj.title.formerTitleNo = this.processObj.title.titleNo;
      this.processObj.title.formerTitleState = this.$store.getters.configState;
      this.processObj.title.formerIssueDate =
        this.processObj.title.titleIssueDate;
      this.processObj.title.titleNo = "";
      this.processObj.isManageLienHolder = true;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Manage Lienholders"];
      delete this.processObj.placard;
    },
    setupTOP() {
      this.isNewOwner = false;
      this.processObj.ownership.isNewOwner = false;
      this.processObj.registration.registrationTypeCode = "T";
      this.processObj.registration.newPlate = {
        plateNo: "",
        class: "1200",
        issueYear: "1994"
      };

      this.processObj.registration.registrationBeginDate = dayjs().format();
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(30, "days")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      ["class", "issueYear", "plateExp", "wheelTax"].forEach(element => {
        this.attributes[element].disabled = true;
      });
      delete this.processObj.title;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Temporary Operators Permit"];
      delete this.processObj.placard;
    },
    setupTAndR() {
      if (!this.processObj.registration)
        this.processObj.registration = registration();
      this.isTitleIssuingTrans = true;
      this.isNewOwner = true;
      this.processObj.ownership.isNewOwner = true;
      this.processObj.registration.registrationTypeCode = "R";
      this.processObj.ownership.purchaseDate = new Date();
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      this.processObj.transaction.transactionType =
        this.transactionTypes["Title And Registration"];
      delete this.processObj.registration.operatingZone;
      delete this.processObj.placard;
    },
    setupUnderCover() {
      //adding new defaults for this
      this.processObj = this._.assign(this.processObj, undercoverDefault());
      this.processObj.undercoverOwners[0].physicalAddress.state =
        this.$store.getters.configState;
      this.processObj.undercoverRegistration.registrationTypeCode = "R";
      this.processObj.undercoverOwnership.purchaseDate = new Date();
      this.processObj.undercoverRegistration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      this.processObj.transaction.transactionType =
        this.transactionTypes["Correction of Registration"]; //needs to be UR but changed until db is updated for testing
      this.$set(
        this.processObj.undercoverRegistration,
        "countyID",
        parseInt(this.$store.getters.countyId)
      );
      delete this.processObj.title;
      delete this.processObj.placard;
    },
    setupTitleOnly() {
      this.isTitleIssuingTrans = true;
      this.isNewOwner = true;
      this.processObj.ownership.isNewOwner = true;
      this.processObj.ownership.purchaseDate = new Date();
      this.processObj.transaction.transactionType =
        this.transactionTypes["Title Only"];
      if (this._.has(this.processObj, "registration"))
        delete this.processObj.registration;
      delete this.processObj.placard;
    },
    setupRegistrationOnly() {
      if (!this.processObj.registration)
        this.processObj.registration = registration();
      this.isNewOwner = true;
      this.processObj.ownership.isNewOwner = true;
      this.processObj.ownership.purchaseDate = new Date();
      delete this.processObj.title;
      this.processObj.registration.registrationTypeCode =
        this.transactionTypes["Registration Only"];
      this.processObj.transaction.transactionType = "RO";
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(1, "year")
        .endOf("month")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      delete this.processObj.registration.operatingZone;
      delete this.processObj.placard;
    },
    setupTitleWithTOP() {
      this.isNewOwner = false;
      this.processObj.ownership.isNewOwner = false;
      this.processObj.transaction.transactionType =
        this.transactionTypes["Title With Temporary Tag"];
      this.processObj.registration.registrationTypeCode = "T";
      this.processObj.registration.newPlate = {
        plateNo: "",
        class: "1200",
        issueYear: "1994"
      };
      ["class", "issueYear", "plateExp", "wheelTax"].forEach(element => {
        this.attributes[element].disabled = true;
      });
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(30, "days")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      delete this.processObj.placard;
    },
    setupTOPOnly() {
      if (!this.processObj.registration)
        this.processObj.registration = registration();
      this.isNewOwner = false;
      this.processObj.ownership.isNewOwner = false;
      this.processObj.registration.plateTransfer = false;
      this.processObj.registration.registrationTypeCode = "T";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Temporary Operators Permit"];
      this.processObj.registration.newPlate = {
        plateNo: "",
        class: "1200",
        issueYear: "1994"
      };
      ["class", "issueYear", "plateExp", "wheelTax"].forEach(element => {
        this.attributes[element].disabled = true;
      });
      this.processObj.registration.registrationBeginDate = dayjs().format();
      this.processObj.registration.registrationExpiresDate = dayjs()
        .add(30, "days")
        .hour(0)
        .minute(0)
        .second(0)
        .format();
      delete this.processObj.title;
      delete this.processObj.placard;
    },
    setupRestoreVehicle() {
      this.processObj.transaction.isRebuild = true;
      this.processObj.title.stateItemTypeID = 120;
      if (this._.has(this.processObj, "registration"))
        this.processObj.registration.registrationTypeCode = "R";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Restore Vehicle Record"];
      this.$set(
        this.processObj.registration.newPlate,
        "isTitleOnlyBuild",
        false
      );
      delete this.processObj.placard;
    },
    setupReassignRegistration() {
      this.processObj.transaction.transactionType =
        this.transactionTypes["Reassign Registration"];
      this.isNewOwner = true;
      this.processObj.registration.registrationTypeCode = "R";
      this.transferWipe();
      this.processObj.registration.plateTransfer = true;
      delete this.processObj.title;
      delete this.processObj.lessor;
      delete this.processObj.stops;
      delete this.processObj.registration.newPlate;
      delete this.processObj.ownership.purchaseDate;
      delete this.processObj.registration.operatingZone;
      delete this.processObj.placard;
    },
    setupNewPlacard() {
      if (!this._.has(this, "processObj.placard"))
        this.$set(this.processObj, "placard", newPlacardScaffold().placard);

      this.processObj.placard.placardTypeCode = "P";
      this.processObj.placard.placardClass = "1016";
      this.processObj.owners[0].customerType = "I";
      this.processObj.transaction.transactionType =
        this.transactionTypes["Placard Transaction"];
      this.processObj.placard.beginDate = new Date();
      this.$set(this.processObj.tempComments, []);
    },
    setupPlacardRenewal() {
      delete this.processObj.placard.placardID;

      if (
        [null, undefined, ""].includes(this.processObj.placard.oldControlNo)
      ) {
        this.processObj.placard.oldControlNo =
          this.processObj.placard.controlNo;
        this.processObj.placard.controlNo = "";
      }

      this.processObj.transaction = defaultTransaction();
      this.processObj.transaction.transactionType =
        this.transactionTypes["Placard Transaction"];
      this.$set(this.processObj.tempComments, []);
      this.$set(this.processObj.transaction, "comments", []);
    },
    setupPlacardReplace() {
      delete this.processObj.placard.placardID;

      if (
        [null, undefined, ""].includes(this.processObj.placard.oldControlNo)
      ) {
        this.processObj.placard.oldControlNo =
          this.processObj.placard.controlNo;
        this.processObj.placard.controlNo = "";
      }

      this.processObj.transaction.transactionType =
        this.transactionTypes["Placard Transaction"];
      this.$set(this.processObj.tempComments, []);
      this.$set(this.processObj.transaction, "comments", []);
      this.processObj.placard["isReplacement"] = true;
    }
  } //end methods
}; //end export
