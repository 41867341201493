var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isInitFinished)?_c('div',{staticClass:"process26"},[_c('div',{staticClass:"detailsTable"},[_c('div',{class:{ 'transaction-menu-container': _vm.menuIsShown }},[(_vm.showLinks && _vm.transactionType !== 'Online Renewal')?_c('TransactionsMenu',{on:{"changeTransaction":_vm.initialize}}):_vm._e()],1),_c('div',{class:{ 'vehicle-detail-container': _vm.menuIsShown }},[_c('div',{attrs:{"id":"detailsRight"}},[_c('h1',[_vm._v(_vm._s(_vm.transactionType))]),_c('div',[_c('div',{staticClass:"panel"},[_c('displayVehicleData',{attrs:{"processObj":_vm.processObj,"isNewOwner":_vm.isNewOwner,"parentShowMoreVehicleInfo":_vm.showMoreVehicleInfo,"readOnly":_vm.readOnly && _vm.canBeAccepted(_vm.renewal),"isTitleIssuingTrans":_vm.isTitleIssuingTrans,"attributes":_vm.attributes,"validation":_vm.validation,"isFromBackButton":_vm.isFromBackButton},on:{"showMoreVehicleInfoChanged":function($event){_vm.showMoreVehicleInfo = $event},"feeChange":_vm.handleFeeChange}})],1)]),(_vm.transactionType == 'Online Renewal')?_c('div',{staticClass:"floating-footer"},[_c('div',{staticClass:"float-foot-item float-foot-left trans-actions"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.cancel(true); }}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Details")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'OnlineRenewals' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Renewals")])],1)],1),(_vm.isProcessable)?[_c('div',{staticClass:"float-foot-item float-foot-right trans-actions"},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.acceptTooltipText(_vm.updatedRenewal) === ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"accept",attrs:{"disabled":!_vm.canBeAccepted(_vm.updatedRenewal) || !_vm.hubAppIsResponsive,"color":"success"},on:{"click":_vm.validateFields}},[_c('span',{staticClass:"white-text"},[_vm._v("Accept")])])],1)]}}],null,false,3666788666)},[_vm._v(" "+_vm._s(_vm.acceptTooltipText(_vm.updatedRenewal))+" ")])],1),_c('div',{staticClass:"float-foot-item float-foot-right"},[_c('div',[_c('v-btn',{staticClass:"reject",attrs:{"color":"error"},on:{"click":_vm.rejectRenewalFinish}},[_c('span',{staticClass:"white-text"},[_vm._v("Reject")])])],1)])]:_vm._e()],2):_c('div',{staticClass:"floating-footer"},[_c('div',{staticClass:"float-foot-item float-foot-left trans-actions"},[_c('div',[(
                  _vm.$store.getters.resultsArray &&
                  _vm.$store.getters.resultsArray.length > 1 &&
                  ![
                    'Title And Registration',
                    'Title Only',
                    'EZ Tag'
                  ].includes(_vm.transactionType)
                )?[_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({ name: 'Results' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Results")])],1)]:[(
                    _vm.processObj.transaction.isDeficientFinish !== true &&
                    (![
                      'Title And Registration',
                      'Title Only',
                      'New Placard',
                      'EZ Tag',
                      'Temporary Tag Only',
                      'Registration Only'
                    ].includes(_vm.transactionType) ||
                      (_vm.transactionType === 'New Placard' &&
                        _vm.vin !== undefined))
                  )?_c('v-btn',{attrs:{"color":"red"},on:{"click":function () { return _vm.cancel(); }}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Details")])],1):(_vm.transactionType == 'EZ Tag')?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({ name: 'EZTag' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("EZ Tag Results")])],1):(_vm.processObj.transaction.isDeficientFinish)?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({ name: 'DeficientTransactions' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Deficients")])],1):_vm._e()]],2)]),_c('div',{staticClass:"float-foot-item float-foot-right trans-actions",attrs:{"id":"rightActions"}},[(_vm.isTitleIssuingTrans)?_c('v-switch',{attrs:{"label":"Assign Title Number"},model:{value:(_vm.assignTitle),callback:function ($$v) {_vm.assignTitle=$$v},expression:"assignTitle"}}):_vm._e(),(_vm.showEzTagRejectInput)?_c('v-text-field',{attrs:{"label":"Reject Reason","solo":"","error":['', null, undefined].includes(_vm.ezTagRejectReason),"error-messages":['', null, undefined].includes(_vm.ezTagRejectReason)
                  ? 'Required'
                  : ''},model:{value:(_vm.ezTagRejectReason),callback:function ($$v) {_vm.ezTagRejectReason=$$v},expression:"ezTagRejectReason"}}):_vm._e(),(!_vm.assignTitle && _vm.isTitleIssuingTrans)?_c('v-select',{attrs:{"placeholder":"Reject Reason","solo":"","error":_vm.incompleteRejectReason == null,"error-messages":_vm.incompleteRejectReason == null ? 'Required' : '',"items":_vm.rejectReasons},model:{value:(_vm.incompleteRejectReason),callback:function ($$v) {_vm.incompleteRejectReason=$$v},expression:"incompleteRejectReason"}}):_vm._e(),_c('div',[(_vm.transactionType === 'EZ Tag')?_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.rejectEZTag}},[_vm._v(" Reject ")]):_vm._e(),_c('v-btn',{attrs:{"id":"actionButton"},on:{"click":function($event){return _vm.validateFields()}}},[(_vm.transactionType == 'Restore Vehicle Record')?[_vm._v("Rebuild")]:(
                    _vm.processObj.transaction.isDeficientFinish != undefined
                  )?[_vm._v("Complete Deficient")]:(_vm.transactionType == 'EZ Tag')?[_vm._v("Complete")]:[_vm._v("Go To Checkout")]],2)],1)],1)])])])]),(_vm.assignedUser != null)?_c('div',{staticClass:"lock-indicator"},[_c('div',[_vm._v("Assigned to "+_vm._s(_vm.assignedUser.fullname))]),_c('v-icon',[_vm._v(" lock ")])],1):_vm._e(),(_vm.transactionType == 'Title Destroy')?_c('v-dialog',{attrs:{"width":"500","persistent":"","value":_vm.transactionType == 'Title Destroy'}},[_c('v-card',[_c('div',{staticClass:"delete-dialog"},[_c('h2',[_vm._v("You have selected to destroy this title.")]),_c('p',[_vm._v("Please confirm your selection.")]),_c('hr'),_c('p',[_vm._v(" VIN: "),_c('b',[_vm._v(_vm._s(_vm.processObj.vehicle.vin))]),_c('br'),_vm._v(" Title: "),_c('b',[_vm._v(_vm._s(_vm.processObj.title.titleNo))]),_c('br'),_vm._v(" Owner: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].customerName))]),_c('br'),(
              _vm.processObj.owners[0].physicalAddress.streetNumber ==
                undefined ||
              _vm.processObj.owners[0].physicalAddress.streetNumber == ''
            )?[_vm._v(" Address: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.address1))])]:[_vm._v(" Address: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.streetNumber)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.address1))])],_c('br'),_vm._v(" City/State/Zip: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.city)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.state)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.postalCode))])],2),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({
              name: 'Details',
              params: {
                vin: _vm.processObj.vehicle.vin,
                make: _vm.processObj.vehicle.makeCode,
                year: _vm.processObj.vehicle.modelYear
              }
            })}}},[_vm._v("Cancel")]),_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"20px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green"},on:{"click":_vm.destroyTitle}},on),[_vm._v(" Destroy Title ")])]}}],null,false,937200952)},[_c('span',[_vm._v("Return mail on title mailed out to customer.")])]),_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"20px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green"},on:{"click":_vm.deTitle}},on),[_vm._v("De-Title")])]}}],null,false,3848047221)},[_c('span',[_vm._v("Remove current title from vin.")])])],1)])],1):_vm._e(),(_vm.transactionType == 'Delete Placard')?_c('v-dialog',{attrs:{"width":"500","persistent":"","value":_vm.transactionType == 'Delete Placard'}},[_c('v-card',[_c('div',{staticClass:"delete-dialog"},[_c('h2',[_vm._v("You have selected to delete this placard.")]),_c('p',[_vm._v("Please confirm your selection.")]),_c('hr'),_c('p',[_vm._v(" Placard No: "),_c('b',[_vm._v(_vm._s(_vm.processObj.placard.controlNo))]),_c('br'),_vm._v(" Owner: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].customerName))]),_c('br'),(
              _vm.processObj.owners[0].physicalAddress.streetNumber ==
                undefined ||
              _vm.processObj.owners[0].physicalAddress.streetNumber == ''
            )?[_vm._v(" Address: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.address1))])]:[_vm._v(" Address: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.streetNumber)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.address1))])],_c('br'),_vm._v(" City/State/Zip: "),_c('b',[_vm._v(_vm._s(_vm.processObj.owners[0].physicalAddress.city)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.state)+" "+_vm._s(_vm.processObj.owners[0].physicalAddress.postalCode))])],2),_c('v-text-field',{attrs:{"label":"Comment","error":_vm.placardDeleteComment == '',"error-messages":_vm.placardDeleteComment == '' ? 'Required' : ''},model:{value:(_vm.placardDeleteComment),callback:function ($$v) {_vm.placardDeleteComment=$$v},expression:"placardDeleteComment"}}),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({
              name: 'PlacardDetails',
              params: { placard: _vm.processObj.placard.controlNo }
            })}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green","disabled":_vm.placardDeleteComment == ''},on:{"click":_vm.deletePlacard}},[_vm._v("Delete Placard")])],1)])],1):_vm._e(),(_vm.showInsurancePrompt)?_c('v-dialog',{attrs:{"width":"500","persistent":"","value":_vm.showInsurancePrompt}},[_c('v-card',[_c('div',{staticClass:"delete-dialog"},[_c('h2',[_vm._v("There is an EIVS stop on this vehicle.")]),_c('p',[_vm._v("Please enter insurance information to continue.")]),_c('hr'),_c('p',[_c('v-autocomplete',{attrs:{"label":"NAIC / Company Name","items":_vm.insuranceCompanyAutoComplete},model:{value:(_vm.processObj.vehicle.insurance.NAIC),callback:function ($$v) {_vm.$set(_vm.processObj.vehicle.insurance, "NAIC", $$v)},expression:"processObj.vehicle.insurance.NAIC"}}),_c('v-text-field',{attrs:{"label":"Policy Number"},model:{value:(_vm.processObj.vehicle.insurance.PolicyNo),callback:function ($$v) {_vm.$set(_vm.processObj.vehicle.insurance, "PolicyNo", $$v)},expression:"processObj.vehicle.insurance.PolicyNo"}})],1),_c('v-btn',{attrs:{"color":"red"},on:{"click":function () { return _vm.cancel(); }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green","disabled":[undefined, ''].includes(_vm.processObj.vehicle.insurance.NAIC) ||
            [undefined, ''].includes(_vm.processObj.vehicle.insurance.PolicyNo)},on:{"click":function($event){_vm.showInsurancePrompt = false}}},[_vm._v("Continue")])],1)])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"400px"},model:{value:(_vm.newTitleDialog),callback:function ($$v) {_vm.newTitleDialog=$$v},expression:"newTitleDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Issue Title?")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_vm._v(" Would you like to issue a new title? ")]),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addNewTitle}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removeNewTitle}},[_vm._v(" No ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }