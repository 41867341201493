import address from "../js/defaultTransactionRequestObjects/address";

export default function () {
  return {
    undercoverVehicleUseCode: "",
    undercoverRegistration: {
      registrationID: 0,
      vehicleID: 0,
      countyID: 0,
      registrationTypeCode: "",
      emissionsTestInd: false,
      estimatedVehicleWorth: null,
      fleetCertificateNo: null,
      fleetPrimaryDomicileCountyID: null,
      registrationBeginDate: null,
      registrationExpiresDate: null,
      cityWheelTaxInd: false,
      wheelTaxCityID: null,
      operatingZone: null,
      outOfStateCode: null,
      statusCode: "",
      statusDate: null,
      undercoverInd: 1,
      blindPlateInd: false,
      renewalNoteDate: null,
      exemptCertificate: null,
      transferredFrom: null,
      registrationYears: 0,
      lastUpdateDate: null,
      lastUpdateUserID: 0,
      newPlate: {
        plateNo: "",
        class: "",
        issueYear: 0
      },
      plateTransfer: false,
      currentMake: "",
      currentYear: 0,
      isSurvivingSpouse: false,
      wheelTaxTypeCode: null,
      firefighterInd: false,
      rescueInd: false,
      isLostStolen: false,
      isReregistration: false
    },
    undercoverTransaction: true,
    undercoverOwners: [
      {
        customerID: 0,
        customerType: "I",
        mailingAddressID: null,
        physicalAddressID: 0,
        customerName: null,
        customerEMail: null,
        customerPhone: null,
        customerPhoneExt: null,
        customerStatusCode: "",
        customerStatusDate: null,
        contactName: null,
        contactPhone: null,
        contactPhoneExt: null,
        massMailInd: false,
        emailRenewalInd: 0,
        deafSpecNeeds: false,
        hearingSpecNeeds: false,
        intellectualSpecNeeds: false,
        developmentalSpecNeeds: false,
        medicalSpecNeeds: false,
        checkReturnCount: null,
        lastUpdateDate: null,
        lastUpdateUserID: 0,
        individualCustomer: {
          lastName: "",
          firstName: "",
          middleName: "",
          nameSuffix: ""
        },
        businessCustomer: null,
        mailingAddress: address(),
        physicalAddress: address(),
        ownershipRightCode: null,
        mailToInd: false,
        lesseeInd: null,
        inCareOf: "",
        stops: null
      },
      {
        individualCustomer: {
          lastName: "",
          firstName: "",
          middleName: "",
          nameSuffix: ""
        },
        businessCustomer: null,
        mailingAddress: address(),
        physicalAddress: address(),
        ownershipRightCode: null,
        mailToInd: false,
        lesseeInd: null,
        inCareOf: "",
        stops: null
      }
    ],
    undercoverOwnership: {
      nameCode: "0",
      ownershipID: 0,
      vehicleID: 0,
      purchaseDate: null,
      conjunctionCode: null,
      insurancePolicyNo: null,
      sellDate: null,
      statusCode: "",
      statusDate: null,
      lastUpdateDate: null,
      lastUpdateUserID: 0,
      leasedInd: false,
      undercoverInd: false,
      vaGrantNo: "",
      vaGrantAmount: 0,
      isNewOwner: false,
      ownerTypeCode: null
    }
  };
}
